import { InvoicesItem } from '../../invoices/types';

import { Container } from '../../../../components/container';
import { StatementSingleInvoicesItem } from './statementSingleInvoicesItem';

import { useGetStatementInvoices } from '../../../../hooks/statements/useGetStatementInvoices';
import {
  SindleStatementInvoicesFormStyled,
  SingleStatementInvoicesStyled,
} from '../styles/statementSingleInvoices.styles';
import { AddInvoice } from '../../../../components/forms/invoices';
import { StatementSingleContentProps } from '../types';
import { RefreshButton } from '../../../../components/buttons';
import toast from 'react-hot-toast';
const StatementSingleInvoices = ({
  statementData,
}: StatementSingleContentProps) => {
  const { invoices, refetchStatements } = useGetStatementInvoices();

  // Calculate the total of uploaded invoices
  const totalUploadedInvoices: number =
    invoices?.results?.items?.reduce(
      (sum: number, invoice: InvoicesItem) => sum + parseFloat(invoice.total),
      0
    ) || 0;
  //Get net total of statement
  const netTotal = statementData.total;
  //Remaining total of statement
  //Convert net total to number
  const netTotalNumber = parseFloat(netTotal);
  const remainingTotal = netTotalNumber - totalUploadedInvoices;
  //Convert remaining total to display commas and 2 decimal places
  const remainingTotalString = remainingTotal.toLocaleString('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
  });

  const handleStatementRefresh = () => {
    return refetchStatements();
  };

  const onSentCallback = () => {
    refetchStatements();
  };

  const loadTickets = () => {
    toast.promise(handleStatementRefresh(), {
      loading: 'Loading Statements ...',
      success: 'Statement updated',
      error: 'Error updating Statements',
    });
  };

  return (
    <SingleStatementInvoicesStyled>
      <Container>
        <h2>Statement Invoices</h2>
        <RefreshButton
          refreshFunction={loadTickets}
          label='Update Statements'
        />
        {/* Display remaining total with fully paid message or amount remaining */}
        <p>
          {remainingTotal <= 0
            ? 'Fully Paid'
            : `Amount Remaining: ${remainingTotalString}`}
        </p>
        {invoices?.results?.items?.length > 0 &&
          invoices.results.items.map((invoice: InvoicesItem) => (
            <StatementSingleInvoicesItem key={invoice.id} invoice={invoice} />
          ))}
        {invoices && remainingTotal > 0 && (
          <SindleStatementInvoicesFormStyled>
            <AddInvoice
              type='statements-page'
              id='newInvoiceForm'
              statement={statementData}
              totalUploadedInvoices={totalUploadedInvoices}
              onSentCallback={onSentCallback}
            />
          </SindleStatementInvoicesFormStyled>
        )}
      </Container>
    </SingleStatementInvoicesStyled>
  );
};

export { StatementSingleInvoices };
