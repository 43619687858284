import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';

import { useParams } from 'react-router';

const useGetStatementInvoices = () => {
  const { hsId } = useUserContext();
  const { id } = useParams();

  const { data, refetch } = useQuery(['Statement Invoices', id], async () => {
    if (hsId) {
      return await api({
        hsId: hsId,
        recordId: id,
      }).statements.getStatementInvoices();
    } else {
      return [];
    }
  });

  return { invoices: data, refetchStatements: refetch };
};

export { useGetStatementInvoices };
