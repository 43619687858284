import styled from 'styled-components';

export const SingleStatementContentStyled = styled.section`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 1rem;
    }
  }
`;
