import { SingleItemHeader } from '../../../../components/singleItem';
import { StatusBadge } from '../../../../components';
import { useParams, Link } from 'react-router-dom';
import { StatementSingleHeaderStyled } from '../styles/statementSingleHeader.styles';

type SingleStatementHeaderProps = {
  reference: string;
  status: string;
};

const StatementSingleHeader = ({
  reference,
  status,
}: SingleStatementHeaderProps) => {
  const { accountId } = useParams();

  return (
    <StatementSingleHeaderStyled>
      <SingleItemHeader>
        <div>
          <h1>Statement: {reference}</h1>
          {status ? <StatusBadge statusType={status} /> : null}
        </div>
        <Link to={accountId ? `/${accountId}/statements` : `/statements`}>
          Back to statements
        </Link>
      </SingleItemHeader>
    </StatementSingleHeaderStyled>
  );
};

export { StatementSingleHeader };
