export const STATEMENT_PROPERTIES = [
  'statement_status',
  'statement_number',
  'net_total',
  'statement_release_date',
  'status',
  'statement_attachment_url',
  'statement_type',
  'statement_number',
  'hs_pipeline',
  'hs_pipeline_stage',
  'is_read',
  'balance_invoiced_so_far',
  'balance_still_to_be_claimed',
];
