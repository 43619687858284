import { InvoicesItem } from '../../invoices/types';

// Components
import {
  Card,
  CardCol,
  CardColLabel,
  CardColContent,
  CardColDate,
  CardColDownload,
  CardColStatus,
  CardActionMenuToggle,
  CardActionMenuDropdown,
} from '../../../../components/card';

import { Link } from 'react-router-dom';
import { Information } from '../../../../components/svg';
import { SingleStatementInvoicesItemStyled } from '../styles/statementSingleInvoices.styles';

// Hooks
import { useState } from 'react';

// Helpers
import { formatters } from '../../../../helpers';

const accountId = null;

type Invoice = {
  invoice: InvoicesItem;
};

const StatementSingleInvoicesItem = ({ invoice }: Invoice) => {
  const [dropdownState, setDropdownState] = useState(false);
  return (
    <>
      <Card>
        <SingleStatementInvoicesItemStyled data-testid='invoices-list-item'>
          <CardCol className='invoice-date'>
            <CardColLabel label='Date' />
            <CardColDate date={invoice.date} />
          </CardCol>
          <CardCol className='invoice-number'>
            <CardColLabel label='Invoice number' />
            <CardColContent content={invoice.invoiceNumber} />
          </CardCol>
          <CardCol className='invoice-pmi'>
            <CardColLabel label='Pmi number' />
            <CardColContent content={invoice.reference} />
          </CardCol>
          <CardCol className='invoice-total'>
            <CardColLabel label='Total(GBP)' />
            <CardColContent
              content={formatters.formatCurrency(invoice.total)}
            />
          </CardCol>
          <CardCol className='pod-card__status invoice-status'>
            <CardColStatus status={invoice.pipelineStage} />
          </CardCol>
          <CardCol className='pod-card__download invoice-download'>
            {invoice.attachmentUrl ? (
              <CardColDownload url={invoice.attachmentUrl} />
            ) : null}
          </CardCol>
          <CardActionMenuToggle
            dropdownState={dropdownState}
            setDropdownState={setDropdownState}
          />
        </SingleStatementInvoicesItemStyled>
        <CardActionMenuDropdown dropdownState={dropdownState}>
          <ul>
            <li>
              <Link
                to={
                  accountId
                    ? `/${accountId}/invoices/${invoice.id}`
                    : `/invoices/${invoice.id}`
                }
              >
                <Information /> View Invoice
              </Link>
            </li>
          </ul>
        </CardActionMenuDropdown>
      </Card>
    </>
  );
};

export { StatementSingleInvoicesItem };
