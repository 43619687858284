import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';

const useGetSingleStatement = () => {
  const { accountId, id } = useParams();
  const { hsId } = useUserContext();

  const getSingleStatement = async () => {
    if (id) {
      return await api({
        hsId: hsId,
        companyId: accountId,
        recordId: id,
      }).statements.getStatement();
    } else {
      return [];
    }
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ['StatementSingle', id],
    queryFn: getSingleStatement,
    enabled: !!hsId,
  });

  return {
    data,
    isLoading,
    error,
  };
};

export { useGetSingleStatement };
